.homepage {
        @include media-breakpoint-up(lg) {
            margin-bottom: -33px;
        }

    &_carousel {
        @include media-breakpoint-down(sm) {
            margin-left: -30px;
            margin-right: -30px;
            margin-top: -40px;
        }

        .carousel {
            @include media-breakpoint-down(md) {
                margin-top: -40px;
                margin-left: -30px;
                margin-right: -30px;
            }
            
            &-item {
                img {
                    min-width: 100%;
                    width: 100%;
                    height: 385px;
                    object-fit: cover;
                    object-position: center;

                    @include media-breakpoint-down(sm) {
                        height: 210px;
                    }
                }
            }
        }

    }

    .carousel-indicators {
     top: 10px;
     bottom: initial;
    
        @include indicators;
    } 
    
    .carousel-caption {
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%);;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 70px 20px 0px 20px;
        text-align: left;

                @include media-breakpoint-down(md) {
                    padding-left: 30px;
                    padding-right: 30px;
                }
    
            * {
                color: #fff;
            }
    
            .small {
                font-weight: bold;
                font-size: $small-text;

                    @include media-breakpoint-down(md) {
                        @include font-calc(10);
                    }
            }
    
            p {
                margin-top: 10px;

                    @include media-breakpoint-down(md) {
                        font-size: $tiny-text;
                    }
            }
    }

    &_noticias {
        a {
            color: $text-color;
        }
    }

    .acessos_items {

        &--item {
            width: calc(25% - 15px) !important;
    
                @include media-breakpoint-down(md) {
                    width: calc(50% - 5px) !important;
                }
        }
    }

    .sidebar {
        @include media-breakpoint-up(lg) {
            margin-bottom: -130px;
        }
    
            .search {
                form {
                    width: 100%;
                    display: flex;
                }
    
                input {
                    width: 100%;
                }
    
                button {
                    margin-top: 0;
                }
            }
    }
}

.noticias {
    &_thumb {
        margin-bottom: 10px;
        width: 100%;
        height: 180px;
        object-fit: cover;
        object-position: center;
    }

    &_titles {
        font-size: $small-text;

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;    
            }
    }

    &_outras {
        margin-top: 30px;

        
        &--item {
            padding-left: 10px;
            border-left: 3px solid $primary-color;
            align-items: center;
            margin-left: 15px;

                @include media-breakpoint-up(md) {
                    padding-right: 30px;
                    margin-right: -15px;
                    display: flex;
                    align-items: center;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                    padding-right: 30px;
                }
            }

            .noticias_titles {
                font-weight: normal;
                margin: 0;
                padding: 0;
            }
    }
}
