.widget_tabs {
    margin-top: 15px;

            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }

        .nav-tabs {
            border: none;
            display: flex;
        }

        .nav-item {
            flex: 1;
        }
        
        .tab-content {
            border: 1px solid $line-color;
            padding: 22px 16px;
        }
        
        .nav-link {
            width: 100%;
            font-size: $small-text;
            font-weight: bold;
            padding: 13px 10px;
            border-radius: 0px 10px 0px 0px;
            background-color: #F5F5F5;
            color: $text-color;
            border: 1px solid $line-color;

                    @include media-breakpoint-down(md) {
                        min-height: 75px;
                    }

                &.active {
                    border: 1px solid $primary-color;
                    background-color: $primary-color;
                    color: $light-color;
                }
        }

        .tabs_list-links {
            padding: 0;
            margin: 0;

                li {
                    list-style: none;
                    margin-bottom: 13px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                }

                a {
                    color: $text-color;
                    font-weight: 500;
                    display: block;
                    font-size: $small-text;
                }
        }

        .tv-assembleia_item {
            width: calc(50% - 6.5px);

                img {
                    margin-bottom: 10px;
                }

                p {
                    font-size: $tiny-text;
                    font-weight: 500;
                }
        }
}

.widget {
    margin-top: 15px;
    border: 1px solid $line-color;
    padding: 22px 16px;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
}

.widget_title {
    font-size: $secondary-text;
    margin-bottom: 20px;
}

.widget_content {
    font-size: $small-text;
}

.widget_link {
    color: $text-color;
    font-size: $small-text;
    font-weight: bold;
    margin: 23px auto 0 auto;
    display: table;
}

.agenda_data {
    font-weight: bold;
    margin-bottom: 0;
}

.widget_carousel {
    margin-top: 15px;

            @include media-breakpoint-down(md) {
                margin-top: 30px;
                margin-bottom: 0;
            }

        .carousel-indicators {
            position: relative;

                li {
                    background-color: #D0D0D0 !important;
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    margin-left: 2.5px;
                    margin-right: 2.5px;
                    margin-top: 10px;

                        &.active {
                            background-color: $primary-color !important;
                        }
                }
        }
}

.acessos {
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }

    &_items {
        justify-content: space-between;
        flex-wrap: wrap;

            &--item {
                background-color: $primary-color;
                padding: 20px;
                text-align: center;
                display: flex !important;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                flex-direction: column;
                margin-bottom: 20px;
                min-height: 200px;
        
                        @include media-breakpoint-down(md) {
                            margin-bottom: 10px;
                            padding: 10px;
                        }
        
                    p {
                        font-size: $small-text;
                        color: $light-color;
                        margin-top: 10px;
                        margin-bottom: 0;
                        font-weight: 500;
                    }
            }

            .slick-dots {
                width: 100%;
                display: flex;
                align-content: center;
                justify-content: center;
                margin: 0;
                padding: 0;
                
                    li {
                        list-style: none;
                        margin-left: 2.5px;
                        margin-right: 2.5px;
                    }

                    .slick-active {
                        button {
                            background-color: $primary-color;
                            color: $primary-color;
                        }
                    }

                    button {
                        background-color: #D0D0D0;
                        color: #D0D0D0;
                        border: none;
                        width: 10px;
                        height: 10px;
                        padding: 0 10px 0 0;
                        overflow: hidden;
                        border-radius: 100%;
                    }
            }

            .slick-slide {
                    @include media-breakpoint-down(md) {
                        padding-right: 10px;
                    }
        
                > div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
    }

    &--icon {
        background-color: $light-color;
        border-radius: 100%;
        font-size: 16px;
        width: 71px;
        height: 71px;
        display: flex;
        align-items: center;
        justify-content: center;

            @include media-breakpoint-down(sm) {
                width: 65px;
                height: 65px;
            }
    }
}

.widget_list {
    &--wimage {
        padding: 0;
        margin: 0;

        li {
            list-style: none;
            padding: 0;
            margin: 0 0 20px 0;

                a {
                    display: flex;
                    color: $text-color;
                    align-items: center;
                }

                &:last-child {
                    margin: 0;
                }
        }

        img {
            max-width: 100px;
            margin-right: 10px;
        }

        h4 {
            font-size: $small-text;
        }

        .content {
            font-size: $tiny-text;
            line-height: 1.3;
        }
    }
}

.sidebar_acessos {
    margin-top: 15px;

        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }

    .acessos_items--item {
        width: calc(50% - 5px) !important;
        margin-bottom: 10px;
    }
}